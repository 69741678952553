import React from "react"
import { useLocation } from "@reach/router"
import { ArticleEntity } from "../../entities/ArticleEntity"
import { Category } from "../Category/Category"
import { StaticPageContainer } from "../StaticPageContainer/StaticPageContainer"
import { StaticPageHeading } from "../StaticPageHeading/StaticPageHeading"
import { Layout } from "../Layout/Layout"
import {
  getSearchAuthorsUrl,
  getSearchCategoriesUrl,
  getSearchUrl,
} from "../../utils/i18nUrls"
import { Badge } from "../Badge/Badge"
import { Link } from "../Link/Link"
import { ListAuthor } from "../ListAuthor/ListAuthor"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { useIntl } from "react-intl"
import { SearchInput } from "../SearchInput/SearchInput"

const Empty = () => (
  <div className="mt-8 text-slate-700 italic w-full p-10 rounded bg-slate-50 border-slate-100 border">
    <FormattedMessage id="search/empty" />
  </div>
)

export const Search: React.FC<{
  page: { title: string; description: string; tab: string }
  articles: ArticleEntity[]
  lang: string
  theme: any
  header: any
  aside: any
  footer: any
  drawer: any
  language: string
  cookies: any
  authors: ArticleEntity["author"][]
  categories: ArticleEntity["category"][]
}> = props => {
  const intl = useIntl()
  const location = useLocation()
  const query = (location.href || "").split("?q=")[1] || ""

  const articles =
    (query.length > 0 &&
      props.articles.filter(({ title, author, category }) => {
        return (
          title.toLowerCase().includes(query.toLowerCase()) ||
          author.name.toLowerCase().includes(query.toLowerCase()) ||
          category.name.toLowerCase().includes(query.toLowerCase())
        )
      })) ||
    []

  const categories =
    (query.length > 0 &&
      props.categories.filter(({ name }) =>
        name.toLowerCase().includes(query.toLowerCase())
      )) ||
    []

  const authors =
    (query.length > 0 &&
      props.authors.filter(({ name }) =>
        name.toLowerCase().includes(query.toLowerCase())
      )) ||
    []

  return (
    <>
      <Layout
        theme={props.theme}
        header={props.header}
        aside={{
          authors: props.aside.tab === "authors" ? [] : authors,
          categories: props.aside.tab === "categories" ? [] : categories,
          articles: props.aside.tab === "articles" ? [] : articles,
          language: props.language,
          searching: true,
          obfuscated: true,
        }}
        drawer={props.drawer}
        language={props.language}
      >
        <StaticPageContainer>
          <div className="lg:hidden mb-6">
            <SearchInput />
          </div>

          <StaticPageHeading
            categories={[
              {
                id: "articles",
                hidden: false,
                lang: props.lang,
                name: intl.formatMessage({ id: "search/tabs/articles" }),
                url: getSearchUrl(props.lang) + location.search,
              },
              {
                id: "authors",
                hidden: false,
                lang: props.lang,
                name: intl.formatMessage({ id: "search/tabs/authors" }),
                url: getSearchAuthorsUrl(props.lang) + location.search,
              },
              {
                id: "categories",
                hidden: false,
                lang: props.lang,
                name: intl.formatMessage({ id: "search/tabs/categories" }),
                url: getSearchCategoriesUrl(props.lang) + location.search,
              },
            ]}
            title={
              <span className="text-slate-900">
                <FormattedMessage id="search/results/title" />{" "}
                <span className="text-slate-400">{query}</span>
              </span>
            }
            lang={props.lang}
            description={<FormattedMessage id="search/results/description" />}
            tab={props.aside.tab}
          />

          {props.aside.tab === "articles" && (
            <>
              {articles.length > 0 && <Category articles={articles} />}
              {articles.length === 0 && <Empty />}
            </>
          )}

          {props.aside.tab === "categories" && (
            <>
              {categories.length > 0 && (
                <div className="flex mt-2 flex-wrap items-center">
                  {categories.map(({ name, url }) => (
                    <Link
                      className="mr-2 inline-block mb-2"
                      key={name}
                      url={url}
                    >
                      <Badge big component="span">
                        {name}
                      </Badge>
                    </Link>
                  ))}
                </div>
              )}
              {categories.length === 0 && <Empty />}
            </>
          )}

          {props.aside.tab === "authors" && (
            <>
              {authors.length > 0 && (
                <div className="">
                  {authors.map(author => (
                    <ListAuthor big {...author} key={author.id} />
                  ))}
                </div>
              )}
              {authors.length === 0 && <Empty />}
            </>
          )}
        </StaticPageContainer>
      </Layout>
    </>
  )
}
